body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

h3.masthead {
  padding:20px;
  background-color: #fff;
  font-size:250% !important;
}

#toboTokenPreview h4 {
  padding-left:40px;
}