/* Fonts */

@font-face {
  font-family: "anonymous-pro-b";
  src: url("assets/fonts/anonymous-pro-b.woff2") format("woff2");
}

@font-face {
  font-family: "anonymous-pro";
  src: url("assets/fonts/anonymous-pro.woff2") format("woff2");
}

@font-face {
  font-family: "inter-regular";
  src: url("assets/fonts/inter-regular.woff2") format("woff2");
}

@font-face {
  font-family: "inter-bold";
  src: url("assets/fonts/inter-bold.woff2") format("woff2");
}

@font-face {
  font-family: "inter-medium";
  src: url("assets/fonts/inter-medium.woff2") format("woff2");
}

@font-face {
  font-family: "oswald-medium";
  src: url("assets/fonts/oswald-medium.woff2") format("woff2");
}

/* Vars */

:root {
  --tobo-blue: #59ccf5;
  --tobo-yellow: #fadf4f;
  --tobo-red: #ff737d;
  --tobo-dark: #241f21;
}

/* TOBOPage */

body {
  background-color: var(--tobo-blue);
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

#toboPage {
  width: 100%;
  margin: auto;
  position: relative;
  line-height: 0;
}

#toboPage main {
  margin-top: 0px;
}

#toboPage h1,
#toboPage h2,
#toboPage h3,
#toboPage h4,
#toboPage h5,
#toboPage h6 {
  margin: 0;
  line-height: 1;
}

#toboPage h3 {
  font-size: 62px;
  font-family: "oswald-medium", sans-serif;
  color: var(--tobo-dark);
}

#toboPage h4 {
  font-family: "anonymous-pro-b", serif;
  font-size: 36px;
  color: var(--tobo-dark);
}

#toboPage p {
  font-family: "anonymous-pro-b", serif;
  font-size: 32px;
  line-height: 1;
  margin: 0;
  color: var(--tobo-dark);
}

#toboPage .clear {
  clear: both;
}

#toboPage button {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  line-height: 1;
}

.toboModule {
  border-radius: 70px 70px 0px 0px;
  position: sticky;
  top: 0px;
  margin-top: -70px;
  min-height: 75vh;
  background-color: #fff;
  display: inline-block;
}

.moduleColumn {
  float: left;
  width: 50%;
  position: relative;
}

@media only screen and (max-width: 767px) {
  #toboPage main {
    margin-top: 60px;
  }

  #toboPage h3 {
    font-size: 30px;
  }

  #toboPage h4 {
    font-size: 24px;
  }

  #toboPage p {
    font-size: 18px;
  }

  .toboModule {
    border-radius: 36px 36px 0px 0px;
    margin-top: -36px;
    position: relative;
    top: 0;
  }

  .moduleColumn {
    width: 100% !important;
  }
}
