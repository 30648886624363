/* TOBOButton */

#toboPage button.toboButton {
  background-color: #000;
  color: #fff;
  border-radius: 14px;
  height: 57px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;
  cursor: pointer;
  font-size: 24px;
  font-family: "inter-bold", sans-serif;
  position: relative;
  outline: 1px solid transparent;
  transition: 0.2s all;
}

#toboPage button.toboButton:not(.mintButton) {
  padding-right: 74px;
  background-image: url("assets/img/button-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 25px center;
}

@media (hover: hover) {
  #toboPage button.toboButton:hover {
    outline: 1px solid #fff;
    filter: invert(1);
    transform: translatey(-6px) rotate(-2deg);
    transform-origin: center center;
    box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.5);
  }
}

#toboPage button.toboButton:active {
  outline: 1px solid #fff;
  filter: invert(1);
}

#toboPage button.toboButton.disabled {
  outline: 1px dashed #fff;
  filter: invert(1);
  cursor: default;
}

button.toboButton span {
  font-size: 18px;
  font-family: "inter-medium", sans-serif;
  position: absolute;
  right: 74px;
}

button.toboButton img {
  height: 33px;
  vertical-align: -11px;
  margin-left: 10px;
}

@media only screen and (max-width: 1023px) {
  #toboPage button.toboButton {
    border-radius: 12px;
    height: 48px;
    font-size: 18px;
  }

  button.toboButton span {
    display: none;
  }
}
