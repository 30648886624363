/* SplashHeader */

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

#toboSplashHeader {
  position: sticky;
  top: 0px;
  overflow: hidden;
  height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img#splashHeaderBg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top left;
}

#splashHeaderBgOverlay {
  width: 100%;
  height: 100%;
  background-color: var(--tobo-blue);
  mix-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
}

.splashHeaderLine {
  background-color: #fff;
  height: 2px;
  width: calc(50% - 250px);
  position: absolute;
  left: 0;
}

#splashHeaderLineXR {
  left: auto;
  right: 0;
}

#splashHeaderLineXR,
#splashHeaderLineXL {
  transform: translateY(-20px);
}

#splashHeaderLineYT,
#splashHeaderLineYB {
  left: calc(50% - 1px);
  width: 2px;
  height: calc(50% - 82px);
  top: 0;
}

#splashHeaderLineYB {
  top: auto;
  bottom: 0;
  height: calc(50% - 106px);
}

#toboSplashHeader h2 {
  position: absolute;
  color: #fff;
  font-family: "anonymous-pro-b", serif;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  transform: translateY(-20px);
}

#splashHeaderLogos {
  position: absolute;
  top: 50%;
  margin-top: 37px;
}

#splashHeaderLogos span {
  color: #fff;
  font-family: "anonymous-pro-b", serif;
  font-size: 20px;
  margin-right: 4px;
  margin-left: 12px;
  vertical-align: -2px;
}

#splashHeaderLogos a {
  margin-left: 12px;
  margin-right: 12px;
}

#splashHeaderLogos a img {
  vertical-align: middle;
  height: 49px;
}

@media only screen and (max-width: 767px) {
  #toboSplashHeader {
    height: 431px;
    top: 60px;
  }

  #splashHeaderLineXL,
  #splashHeaderLineXR {
    width: calc(50% - 160px);
  }

  #splashHeaderLineYT {
    height: calc(50% - 48px);
  }

  #splashHeaderLineYB {
    height: calc(50% - 112px);
  }

  #toboSplashHeader h2 {
    font-size: 30px;
    line-height: 1;
  }

  #splashHeaderLogos {
    text-align: center;
    margin-top: 52px;
  }

  #splashHeaderLogos span {
    font-size: 12px;
    display: block;
    line-height: 1;
    margin: 0;
    margin-bottom: 6px;
  }

  #splashHeaderLogos a {
    margin-left: 8px;
    margin-right: 8px;
  }

  #splashHeaderLogos a img {
    height: 24px;
  }
}

/* SplashModule1 */

#toboSplashModule1 {
  padding-bottom: 164px;
}

img#splashModule1Shape {
  width: 30%;
  position: absolute;
  right: 1.3%;
  top: 593px;
}

#toboSplashModule1 .moduleColumn.splashModuleColumnL img {
  width: 100%;
  padding-left: 9.9%;
  padding-right: 7.5%;
  margin-top: 118px;
}

#splashModule1Circle {
  position: absolute;
  width: 29.7%;
  aspect-ratio: 4/4;
  background-color: var(--tobo-blue);
  mix-blend-mode: multiply;
  border-radius: 999px;
  top: 118px;
  margin-top: 22.5%;
  right: 3.9%;
}

img#splashModule1Logo {
  margin-left: 3.4%;
  margin-top: 130px;
  width: 65.2%;
}

#toboPage #toboSplashModule1 .moduleColumn.splashModuleColumnR p {
  margin-bottom: 25px;
  margin-top: 26px;
  margin-left: 3.4%;
  width: 70.6%;
}

#toboSplashModule1 .moduleColumn.splashModuleColumnR button {
  margin-top: 10px;
  margin-left: 3.4%;
  width: 438px;
}

#toboSplashModule1 #splashModule1Mint button {
  margin-top: 31px;
}

@media (hover: hover) {
  #toboSplashModule1 #splashModule1Mint button:hover {
    background-color: var(--tobo-light);
  }
}

#splashModule1Mint span {
  line-height: 1;
  margin-left: 3.4%;
  margin-top: 10px;
  display: block;
  padding-left: 20px;
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  color: var(--tobo-dark);
}

#splashModule1Banner {
  background-color: var(--tobo-blue);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  width: 460px;
  height: 56px;
  border-radius: 0 0 38px 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "inter-bold", sans-serif;
  color: #fff;
  font-size: 22px;
}

#splashModule1Banner span {
  font-family: "inter-medium", sans-serif;
}

@media only screen and (max-width: 1023px) {
  #toboSplashModule1 .moduleColumn.splashModuleColumnR button {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  #toboSplashModule1 {
    padding-bottom: 83px;
    position: relative;
  }

  #toboSplashModule1 .moduleColumn.splashModuleColumnR {
    padding-left: 20px;
    padding-right: 20px;
  }

  img#splashModule1Shape {
    top: 800px;
  }

  #toboSplashModule1 .moduleColumn.splashModuleColumnL .toboMint {
    margin-top: 59px;
  }

  #splashModule1Circle {
    top: 59px;
  }

  img#splashModule1Logo {
    margin-top: 65px;
  }

  #splashModule1Banner {
    width: 320px;
    height: 38px;
    font-size: 16px;
    border-radius: 0px 0px 36px 36px;
  }
}

/* SplashModule2 */

#toboSplashModule2 {
  background-color: var(--tobo-yellow);
  padding-bottom: 131px;
}

#toboSplashModule2 .moduleColumn.splashModuleColumnL {
  width: calc(100% - 480px);
}

#toboSplashModule2 h3 {
  margin-left: 80px;
  margin-top: 116px;
}

#toboPage #toboSplashModule2 p {
  margin-left: 80px;
  margin-top: 29px;
}

#toboSplashModule2 .moduleColumn.splashModuleColumnL button {
  margin-left: 80px;
  margin-top: 33px;
}

#toboSplashModule2 #splashModule2Mint button {
  margin-top: 31px;
}

#splashModule2Mint span {
  line-height: 1;
  margin-left: 8.6%;
  margin-top: 10px;
  display: block;
  padding-left: 10px;
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  color: var(--tobo-dark);
}

#toboSplashModule2 .moduleColumn.splashModuleColumnR {
  width: 480px;
  padding-left: 60px;
}

#toboSplashModule2 .moduleColumn.splashModuleColumnR .toboMint {
  width: 88%;
  position: relative;
  margin-top: 79px;
  right: 0;
}

.splashModule2Circle {
  position: absolute;
  width: 17.3%;
  aspect-ratio: 4/4;
  background-color: var(--tobo-blue);
  mix-blend-mode: multiply;
  border-radius: 999px;
  top: 79px;
  right: 15.2%;
  margin-right: 63.55%;
}

#splashModule2Circle2 {
  background-color: var(--tobo-red);
  margin-top: 20%;
}

@media only screen and (max-width: 767px) {
  #toboSplashModule2 .moduleColumn.splashModuleColumn {
    width: 90%;
  }

  #toboSplashModule2 h3 {
    margin-top: 58px;
    margin-left: 0;
  }

  #toboPage #toboSplashModule2 p {
    margin-left: 0;
    margin-right: 0;
  }

  #toboSplashModule2 .moduleColumn.splashModuleColumnL button {
    margin-left: 0;
    margin-right: 0;
  }

  #toboSplashModule2 .moduleColumn.splashModuleColumnR .toboMint {
    margin-top: 40px;
    width: 100%;
  }

  #toboSplashModule2 .moduleColumn.splashModuleColumnL {
    padding: 0 24px;
  }

  #toboSplashModule2 .moduleColumn.splashModuleColumnR {
    padding-right: 30px;
    padding-left: 30px;
  }

  .splashModule2Circle {
    top: 40px;
  }
}

/* SplashModule3 */

#toboSplashModule3 {
  background-color: #ff737d;
  padding-bottom: 70px;
}

#toboSplashModule3 .moduleColumn.splashModuleColumnL img {
  width: 100%;
  padding-left: 5.5%;
  padding-right: 6.4%;
  margin-top: 16px;
}

#toboSplashModule3 .moduleColumn.splashModuleColumnR {
  padding-bottom: 64px;
}

#toboSplashModule3 h3 {
  margin-left: 5.4%;
  margin-top: 150px;
}

#toboPage #toboSplashModule3 p {
  margin-left: 5.4%;
  margin-top: 28px;
  width: 81.6%;
}

#splashModule3Social {
  margin-left: 5.4%;
  margin-top: 51px;
  width: 270px;
}

#splashModule3Social span {
  font-family: "oswald-medium", sans-serif;
  display: block;
  line-height: 1;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--tobo-dark);
}

#splashModule3Social a {
  line-height: 1;
  color: var(--tobo-dark);
  font-size: 22px;
  font-family: "inter-bold", sans-serif;
  margin-top: 5px;
  display: inline-block;
  width: 135px;
}

#splashModule3Social a:active {
  filter: invert(1);
}

@media (hover: hover) {
  #splashModule3Social a:hover {
    filter: invert(1);
  }
}

@media only screen and (max-width: 767px) {
  #toboSplashModule3 {
    padding-bottom: 40px;
  }

  #toboSplashModule3 .moduleColumn.splashModuleColumnR {
    padding-bottom: 32px;
    padding-left: 24px;
  }

  #toboSplashModule3 .moduleColumn.splashModuleColumnL {
    padding: 0 24px;
  }

  #toboSplashModule3 h3 {
    margin-top: 20px;
    margin-left: 0;
  }

  #toboPage #toboSplashModule3 p {
    margin-left: 0;
  }

  #splashModule3Social {
    width: 200px;
    margin-left: 0;
  }

  #splashModule3Social span {
    font-size: 18px;
  }

  #splashModule3Social a {
    font-size: 16px;
    width: 100px;
  }
}
/* SplashModule4 */

#toboSplashModule4 {
  background-color: var(--tobo-blue);
  padding-bottom: 131px;
}

#toboSplashModule4 .moduleColumn.splashModuleColumnL {
  width: calc(100% - 480px);
}

#toboSplashModule4 h3 {
  margin-left: 80px;
  margin-top: 116px;
}

#toboPage #toboSplashModule4 p {
  margin-left: 80px;
  margin-top: 29px;
}

#toboSplashModule4 .moduleColumn.splashModuleColumnL button {
  margin-left: 80px;
  margin-top: 33px;
}

#toboSplashModule4 .moduleColumn.splashModuleColumnR {
  width: 480px;
  padding-left: 60px;
}

#toboSplashModule4 .moduleColumn.splashModuleColumnR .toboMint {
  width: 88%;
  position: relative;
  margin-top: 79px;
  right: 0;
}

.splashModule4Circle {
  position: absolute;
  width: 17.3%;
  aspect-ratio: 4/4;
  background-color: var(--tobo-blue);
  mix-blend-mode: multiply;
  border-radius: 999px;
  top: 79px;
  right: 15.2%;
  margin-right: 63.55%;
}

#splashModule4Circle2 {
  background-color: var(--tobo-red);
  margin-top: 20%;
}

@media only screen and (max-width: 767px) {
  #toboSplashModule4 .moduleColumn.splashModuleColumn {
    width: 90%;
    margin-left: 0;
  }

  #toboSplashModule4 h3 {
    margin-top: 58px;
    margin-left: 24px;
  }

  #toboPage #toboSplashModule4 p {
    margin-left: 24px;
    margin-right: 24px;
  }

  #toboSplashModule4 .moduleColumn.splashModuleColumnR .toboMint {
    margin-top: 40px;
    width: 100%;
  }

  #toboSplashModule4 .moduleColumn.splashModuleColumnL button {
    margin-left: 24px;
  }

  #toboSplashModule4 .moduleColumn.splashModuleColumnR {
    padding: 0 24px;
  }

  .splashModule4Circle {
    top: 40px;
  }
}
